:root {
  --dark: #041f3f;
  --green: #0b9485;
  --green3: #dcf3f0;
  --white: #ffffff;
  --gray1: #818181;
  --gray2: #dedede;
  --gray3: #f3f6fb;
  --gray4: #818181;
  --gray5: #dedede;
  --gray6: #f3f6fb;
  --blue: #4339f2;
  --blue-25: #4339f23f;
  --red: #ff4747;
  --red-25: #ff47473f;
  --yellow: #ffb200;
  --yellow-25: #ffb2003f;
  --status: #009864;
  --status-bg: #bbf5e2;
  --Outside-of-Scope: #818181;
  --Outside-of-Scope-25: #8181813f;
  --gray: #6fcf97;
  --background: #f2f2f2;
  --topbar-border: rgba(28, 28, 28, 0.1);
  --gray9: #6b7280;
  /* scope1 */
  --Scope-1-Stationary-Combustion: #4339f2;
  --Scope-1-Mobile-Combustion-On-road: #165baa;
  --Scope-1-Mobile-Combustion-Off-Road: #042a8d;
  --Scope-1-CH4-Wastewater-Treatment: #b9b5fd;
  --Scope-1-Waste-Disposal: #1083f3;
  --Scope-1-Refrigerant: #90deff;
  --Scope-1-CH4-Septic-Tank: #216d98;
  --Scope-1-Fire-Extinguisher: #44f4ff;
  --Scope-1-Fertilizer: #a6e6fb;
  --Scope-1-Other-Fugitive-Emission: #00a3ff;
  --Scope-1-Stationary-Combustion-25: #4339f23f;
  --Scope-1-Mobile-Combustion-On-road-25: #165baa3f;
  --Scope-1-Mobile-Combustion-Off-Road-25: #042a8d3f;
  --Scope-1-CH4-Wastewater-Treatment-25: #b9b5fd3f;
  --Scope-1-Waste-Disposal-25: #1083f33f;
  --Scope-1-Refrigerant-25: #90deff3f;
  --Scope-1-CH4-Septic-Tank-25: #216d983f;
  --Scope-1-Fire-Extinguisher-25: #44f4ff3f;
  --Scope-1-Fertilizer-25: #a6e6fb3f;
  --Scope-1-Other-Fugitive-Emission-25: #00a3ff3f;
  /* scope2 */
  --Scope-2-Energy-indirect-GHG-emissions: #d71a1a;
  --Scope-2-Out-of-Scope: #f9aaaa;
  --Scope-2-Energy-indirect-GHG-emissions-25: #d71a1a3f;
  --Scope-2-Out-of-Scope-25: #f9aaaa3f;
  /* scope3 */
  --Scope-3-upstream1: #78350f;
  --Scope-3-upstream2: #b45309;
  --Scope-3-upstream3: #f59e0b;
  --Scope-3-upstream4: #facc15;
  --Scope-3-upstream5: #fde047;
  --Scope-3-upstream6: #fef08a;
  --Scope-3-upstream7: #ecfccb;
  --Scope-3-upstream8: #bef264;
  --Scope-3-downstream9: #84cc16;
  --Scope-3-downstream10: #4d7c0f;
  --Scope-3-downstream11: #365314;
  --Scope-3-downstream12: #86efac;
  --Scope-3-downstream13: #22c55e;
  --Scope-3-downstream14: #15803d;
  --Scope-3-downstream15: #166534;
  --Scope-3-upstream1-25: #ffa75c3f;
  --Scope-3-upstream2-25: #041f3f3f;
  --Scope-3-upstream3-25: #ff7a003f;
  --Scope-3-upstream4-25: #bf975c3f;
  --Scope-3-upstream5-25: #a157003f;
  --Scope-3-upstream6-25: #fbff393f;
  --Scope-3-upstream7-25: #b8d4443f;
  --Scope-3-upstream8-25: #7975143f;
  --Scope-3-downstream9-25: #7dca003f;
  --Scope-3-downstream10-25: #ffd0a53f;
  --Scope-3-downstream11-25: #fdeb463f;
  --Scope-3-downstream12-25: #fce48e3f;
  --Scope-3-downstream13-25: #23f6003f;
  --Scope-3-downstream14-25: #a3ca353f;
  --Scope-3-downstream15-25: #d8e44a3f;
  /* outsideOfScope */

  --Outside-of-Scope-Biogenic-emission: #8d8d8d;
  --Outside-of-Scope-Refrigerant: #a3a3a3;
  --Outside-of-Scope-Other-fugitive-emission: #433f3f;
  --Outside-of-Scope-Out-of-Scope: #8d8d8d;
  --Outside-of-Scope-Out-of-Scope-25: #8d8d8d3f;

  /* update */
  --status-pending-text: #e39e00;
  --status-pending-bg: #ffe8b4;
  --status-rejected-failed-bg: #ffddde;
  --status-rejected-failed-text: #e54447;
  --status-draft-inactive-bg: #dedede;
  --status-draft-inactive-text: #818181;
  --status-waiting-leased-in-text: #1c9de5;
  --status-waiting-leased-in-bg: #b4e4ff;
  --status-approved-complete-active-text: #009864;
  --status-approved-complete-active-bg: #bbf5e2;
  --status-rejected-failed-bg: #ffddde;
  --status-rejected-failed-text: #e54447;
  --status-owned-and-leased-out-bg: #ecd7fc;
  --status-owned-and-leased-out-text: #8a0ded;
  --gray5: #dedede;
  --gray8: #f2f2f2;

  /* table */
  --unstable_DataGrid-overlayBackground: rgba(242, 242, 242, 0.38);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img.black {
  filter: invert(1);
}

img.green {
  filter: invert(1%) sepia(29%) saturate(6845%) hue-rotate(84deg)
    brightness(96%) contrast(92%);
}

img.blue {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

img.status {
  filter: invert(40%) sepia(43%) saturate(4759%) hue-rotate(139deg)
    brightness(89%) contrast(101%);
}

/* .css-1jcm0yw-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  background-color: var(--gray1);
  -webkit-text-fill-color: var(--gray1);
} */
